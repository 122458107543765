import BillingDetailView from '@/components/BillingDetail/BillingDetailView';

const defaultProps = {};

type BillingDetailContainerProps = {
  location: Location;
  organizationId: string | number;
} & typeof defaultProps;

export function BillingDetailContainer({
  organizationId,
}: BillingDetailContainerProps) {
  const billing = {};

  return (
    <BillingDetailView
      billing={billing}
      error={false}
      loading={false}
      organizationId={organizationId}
    />
  );
}

BillingDetailContainer.defaultProps = defaultProps;

export default BillingDetailContainer;

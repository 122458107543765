import { useTranslation, Trans } from 'react-i18next';
import { Link as GatsbyLink } from 'gatsby';

import Box from '@mui/material/Box';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Paper from '@mui/material/Paper';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import PageError from '@/components/core/PageError';
import PageLoading from '@/components/core/PageLoading';

const defaultProps = {};

type BillingDetailViewProps = {
  billing: any;
  error: any;
  loading: boolean;
  organizationId: string | number;
} & typeof defaultProps;

export function BillingDetailView({
  billing,
  error,
  loading,
  organizationId,
}: BillingDetailViewProps) {
  const { t } = useTranslation();
  return (
    <>
      <Box mb={2}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link
            color="inherit"
            component={GatsbyLink}
            to={`/organizations/${organizationId}`}
            underline="hover"
          >
            {organizationId}
          </Link>
          <Typography color="text.primary" sx={{ minWidth: `50px` }}>
            {t(`billing.billing`)}
          </Typography>
        </Breadcrumbs>
        {!error && (
          <Stack
            direction={{ xs: `row`, sm: `row` }}
            justifyContent="space-between"
          >
            <Box>
              <Typography
                color="inherit"
                component="div"
                variant="h1"
                sx={{ minWidth: `400px`, mb: { xs: 1, sm: 0 } }}
              >
                {t(`billing.billing`)}
              </Typography>
              <Stack
                direction="row"
                spacing={1.5}
                sx={{ my: 1.5 }}
                alignItems="center"
              >
                {loading ? (
                  <>
                    <Skeleton width="200px" />
                  </>
                ) : (
                  <>
                    <Typography color="text.secondary">
                      <Trans
                        i18nKey="billing.last-invoiced-at"
                        values={{
                          date: new Intl.DateTimeFormat(`en-US`, {
                            dateStyle: `medium`,
                            timeStyle: `short`,
                          }).format(new Date()),
                        }}
                      />
                    </Typography>
                  </>
                )}
              </Stack>
            </Box>
          </Stack>
        )}
      </Box>
      <Box>
        {loading ? (
          <PageLoading></PageLoading>
        ) : error ? (
          <PageError>{error.message}</PageError>
        ) : billing ? (
          <Grid container spacing={0}>
            <Grid item xs={12}>
              <Paper elevation={0} component={Card}>
                <CardHeader title="Details" sx={{ px: 0, py: 1.5 }} />
                <CardContent sx={{ p: 0 }}>
                  <List>
                    <ListItem sx={{ px: 0 }}>
                      <ListItemText
                        sx={{ maxWidth: 1 / 6 }}
                        primaryTypographyProps={{ sx: { color: `grey.700` } }}
                        primary={`ID`}
                      />
                      <ListItemText primary={`TEST!!!!`} />
                    </ListItem>
                  </List>
                </CardContent>
                <CardActions></CardActions>
              </Paper>
            </Grid>
          </Grid>
        ) : (
          <></>
        )}
      </Box>
    </>
  );
}

BillingDetailView.defaultProps = defaultProps;

export default BillingDetailView;

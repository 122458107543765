import { withAuthenticationRequired } from '@auth0/auth0-react';

import AppFrame from '@/components/AppFrame';
import BillingDetail from '@/components/BillingDetail';

export function BillingIndex({
  location,
  organizationId,
}: {
  location: Location;
  organizationId: string;
}) {
  return (
    <AppFrame organizationId={organizationId}>
      <BillingDetail location={location} organizationId={organizationId} />
    </AppFrame>
  );
}

export default withAuthenticationRequired(BillingIndex);
